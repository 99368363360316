@import '../../mixins';

.home {
    &_lu {

        &_wrapper {

            border-top: 5px solid $uwkc-orange;
        }
    }
}